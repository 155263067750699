import { cache } from "@solidjs/router";
import { BLOG_URL, SitePath } from "~/configs/paths";

export const BLOG_FEED_URL = `https://blog.jonathanlau.io/feed/feed.json`;
export const SPEEDLIFY_URL = "https://speedlify.jonathanlau.io";
export const SPEEDLIFY_HASH = "3cab4432";
export const fetchLatest = async (url: string) => {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
  });
  // console.log(response.json())
  return response.json();
};
function isLastCharAlphanumeric(str: string) {
  return /\w$/.test(str);
}

export function excludeUntilAlphanumeric(str: string) {
  let idx = str.length - 1;
  while (isLastCharAlphanumeric(str.at(idx)!) && idx >= 0) {
    idx--;
  }
  const res = str.substring(0, idx) + "...";
  return res;
}
function getOrdinalSuffix(i: number) {
  const j = i % 10;
  const k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function convertDate(dateStr: string) {
  const date = new Date(dateStr);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const readableDate = `${monthNames[date.getMonth()]} ${getOrdinalSuffix(date.getDate())}, ${date.getFullYear()}`;

  return readableDate;
}

export function convertMMDDYYYFormat(dateStr: string) {
  const date = new Date(dateStr);

  const readableDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  return readableDate;
}
type PartialSitePath = Partial<SitePath>;

export function getEncodedPath(link: PartialSitePath) {
  const encodedPath = encodeURIComponent(link.path!);
  const href = link.isExternal ? `/proxy_redir?url=${encodedPath}` : link.path;
  return href as string;
}

export function getAssetUrl(path: string) {
  if (import.meta.env.SSR) {
    // On the server, return a path relative to the public directory
    return `/assets/${path}`;
  } else {
    // On the client, use import.meta.url to get the correct URL
    return new URL(`./assets/${path}`, import.meta.url).href;
  }
}
export const buildDate = convertMMDDYYYFormat(import.meta.env.BUILD_DATE);

export const fetchVitals = cache(async () => {
  "use server";
  const url = `${SPEEDLIFY_URL}/api/${SPEEDLIFY_HASH}.json`;
  try {
    const result = await fetch(url).then((res) => res.json());
    if (result) {
      return result;
    } else {
      console.error("Fetched data is undefined");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
}, "vitals");

function transformToExternal(arr: string[]): Array<SitePath> {
  return arr.reduce((acc: Array<SitePath>, curr: string) => {
    const path = `${BLOG_URL}/tags/${curr.replace(/\s+/g, "-")}`;
    const obj: SitePath = {
      title: curr,
      path,
      isExternal: true,
    };
    return [obj, ...acc];
  }, []);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatPosts(items: any[]) {
  return items.slice(0, 6).map((post) => {
    const path = getEncodedPath({ path: post.url, isExternal: true });
    return {
      title:
        post.title.length > 77
          ? excludeUntilAlphanumeric(post.title.substring(0, 77))
          : post.title,
      description:
        post.description.length > 100
          ? excludeUntilAlphanumeric(post.description.substring(0, 90))
          : post.description,
      text: post.date_published,
      path,
      reading_time: post.reading_time,
      tags: transformToExternal(post.tags[0].split(",")),
      isExternal: true,
    };
  });
}
